import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ControlValidationFailure, isControlValidationFailure} from '../../model/control-validation-failure';
import {TranslateService} from '@ngx-translate/core';
import {Wholesale} from '../../model/wholesale';
import {WholesaleStatus} from '../../model/wholesale-status';
import {UserInfoService} from '../../service/user-info-service';
import {SpecificationCommitment} from '../../model/specification-commitment';
import {WholesaleService} from '../../service/wholesale-service';
import {WholesaleVehicle} from '../../model/wholesale-vehicle';
import {MessageService} from 'primeng/api';
import {WholesaleComponent} from '../wholesale/wholesale.component';
import {forkJoin, map} from 'rxjs';
import {ControlTarget} from '../../model/control-target';
import {Permissions} from '../../service/init-service';
import {SpinnerService} from '../../service/spinner-service';

@Component({
	selector: 'wholesale-save-approve',
	templateUrl: './wholesale-save-approve.component.html',
	styleUrls: ['./wholesale-save-approve.component.scss'],
	providers: [MessageService]
})
export class WholesaleSaveApproveComponent implements OnInit, OnChanges {
	@Input() wholesaleData!: Wholesale[];
	@Input() wholesaleStatusData: WholesaleStatus;
	@Input() vehicles: Map<string, WholesaleVehicle>;
	@Input() vehicleLine: string;
	@Input() validateCommit: Event;
	@Input() validateFreeDemand: Event;
	@Input() controlTargets: ControlTarget[];
	@Input() totalCommitmentCount: number;
	@Input() totalFreeDemandPercent: number;
	@Input() volumeLevelCommitmentCount: number;
	@Input() wholesaleDataSaveAllowed: boolean;
	@Input() wholesaleDataApproveAllowed: boolean;
	@Input() productionCycle: string;
	@Input() commitmentStatus: string;
	@Output() isCommitAllowedEmitter = new EventEmitter<any>();
	@Output() totalCommitmentCountEmitter = new EventEmitter<any>();
	@Output() totalFreeDemandCountEmitter = new EventEmitter<any>();
	dealerCode: string;
	market: string;
	approveTooltip: string;
	saveTooltip: string;
	freeDemandTooltip: string;
	isSaveAllowed: boolean;
	isApproveAllowed: boolean;
	isCommitAllowed: boolean;
	wholesaleStatus: boolean;
	permissions: Permissions;

	specificationCommitmentList!: SpecificationCommitment[];
	constructor(private wholesaleService: WholesaleService,

				public spinnerService: SpinnerService,
				private translate: TranslateService,
				private userInfoService: UserInfoService,
				public parentComponent: WholesaleComponent) { }

	ngOnInit(): void {
		this.permissions = this.getUserSalesCodePermission();
		this.userInfoService.getDealerCode().subscribe(dealerCode => {
			this.dealerCode = dealerCode;
		});
		this.userInfoService.getMarket().subscribe(market => {
			this.market = market;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.permissions = this.getUserSalesCodePermission();
		this.wholesaleStatus = this.wholesaleStatusData && this.wholesaleStatusData.wholesaleStatus;
		if (this.validateCommit) {
			this.sumAndValidateCommitment(this.validateCommit);
			this.validateCommit = undefined;
		}
		if (this.validateFreeDemand) {
			this.sumAndValidateFreeDemand(this.validateFreeDemand);
			this.validateFreeDemand = undefined;
		}
		this.verifyCommitAllowed();
		this.isCommitAllowedEmitter.emit(this.isCommitAllowed);
		this.totalCommitmentCountEmitter.emit(this.totalCommitmentCount);
		this.totalFreeDemandCountEmitter.emit(this.totalFreeDemandPercent);
		if (changes.totalCommitmentCount || changes.wholesaleData) {
			this.verifySaveAllowed();
			this.verifyApproveAllowed();
			if (this.wholesaleData && this.wholesaleStatusData && this.volumeLevelCommitmentCount === 0) {
				this.translate.get('wbdo.wholesale.loadFailure.noVolumeCommitment').subscribe({
					next: (message) => {
						this.parentComponent.showMessageServiceError(message, false,'info');
					}
				});
			}
		}

	}

	getUserSalesCodePermission(): Permissions {
		let permissions: Permissions[];
		this.userInfoService.getPermissions().subscribe(perms=> {
			permissions = perms;
		});
		let saleCodePermissions = permissions.filter((salesCodePermission) => salesCodePermission.salesCode === this.dealerCode);
		if (saleCodePermissions.length === 0) {
			saleCodePermissions = permissions.filter((salesCodePermission) => salesCodePermission.salesCode === 'ALL');
		}
		return saleCodePermissions.length > 0 ? saleCodePermissions[0] : null;
	}

	sumAndValidateCommitment(_event: Event): void {
		let a = 0;
		this.wholesaleData.forEach(w => {
			w.dealerOrder = !!w.dealerOrder && w.dealerOrder >= 0 && w.dealerOrder <= 999 ? Math.floor(w.dealerOrder) : 0;
			a = a + Number(w.dealerOrder);
		});
		this.totalCommitmentCount = a;
		this.verifyApproveAllowed();
		this.verifySaveAllowed();
		if (this.volumeLevelCommitmentCount !== this.totalCommitmentCount) {
			this.translate.get('wbdo.wholesale.approveCountMismatch').subscribe(x => this.approveTooltip = x);
		} else {
			this.approveTooltip = '';
		}
	}

	sumAndValidateFreeDemand(_event: Event): void {
		let a = 0;
		this.wholesaleData.forEach(w => {
			w.freeDemandMix = !!w.freeDemandMix && w.freeDemandMix >= 0 && w.freeDemandMix <= 100 &&
			Number(w.freeDemandMix) + a <= 100 ? Math.floor(w.freeDemandMix) : 0;
			a = a + Number(w.freeDemandMix);
		});
		this.totalFreeDemandPercent = a;
		if (this.totalFreeDemandPercent < 100) {
			this.translate.get('wbdo.wholesale.freeDemandPercentageError').subscribe(x => this.freeDemandTooltip = x);
		} else {
			this.freeDemandTooltip = '';
		}
	}

	verifySaveAllowed(): void {
		if (this.wholesaleData && this.wholesaleStatusData) {
			const isUserSaveAllowed = this.permissions === null ? false : this.permissions.saveWholesale;
			const commitCountMatch = this.totalCommitmentCount === this.volumeLevelCommitmentCount;
			this.isSaveAllowed = this.wholesaleStatus && this.wholesaleDataSaveAllowed && isUserSaveAllowed
				&& commitCountMatch;

			if (this.volumeLevelCommitmentCount === 0) {
				this.isSaveAllowed = false;
				this.translate.get('wbdo.wholesale.loadFailure.noVolumeCommitment').subscribe({
					next: (message) => {
						this.saveTooltip = message;
					}
				});
			} else {
				this.saveTooltip = '';
			}
		} else {
			this.isSaveAllowed = false;
			this.translate.get('wbdo.wholesale.wholesaleClosed').subscribe(x => this.saveTooltip = x);
		}
	}

	verifyApproveAllowed() {
		if (this.wholesaleData && this.wholesaleStatusData) {
			const isUserApproveAllowed = this.permissions === null ? false : this.permissions.commitWholesale;
			const commitCountMatch = this.totalCommitmentCount === this.volumeLevelCommitmentCount;
			this.isApproveAllowed = (this.wholesaleStatus ||
					(this.permissions !== null && this.permissions.somsWholesale && this.wholesaleStatusData.controlOverrideStatus)) &&
				this.wholesaleDataApproveAllowed &&
				isUserApproveAllowed && commitCountMatch;
			if (this.volumeLevelCommitmentCount === 0) {
				this.translate.get('wbdo.wholesale.loadFailure.noVolumeCommitment').subscribe({
					next: (message) => {
						this.approveTooltip = message;
					}
				});
			} else {
				this.approveTooltip = '';
			}
		} else {
			this.translate.get('wbdo.wholesale.wholesaleClosed').subscribe(x => this.approveTooltip = x);
		}
	}
	verifyCommitAllowed() {
		if (this.wholesaleData && this.wholesaleStatusData) {
			const isUserApproveAllowed = this.permissions === null ? false : this.permissions.commitWholesale;
			this.isCommitAllowed = (this.wholesaleStatusData && this.wholesaleStatusData.wholesaleStatus)
				&& this.wholesaleDataApproveAllowed && isUserApproveAllowed;
			// Check for soms + control override
			if (this.permissions !== null && this.permissions.somsWholesale && this.wholesaleStatusData.controlOverrideStatus) {
				this.isCommitAllowed = true;
			}
		}
	}
	buildControlValidationFailureToast(validationErrors: ControlValidationFailure) {
		return forkJoin([
			this.translate.get('wbdo.wholesale.validationErrorPartA'),
			this.translate.get('wbdo.wholesale.validationErrorPartB'),
			this.translate.get('wbdo.wholesale.validationErrorPartFinal'),
		]).pipe(
			map(([validationMessageA, validationMessageB, validationMessageFinal]) => {
				const messageParts: string[] = [];
				for (const failure of validationErrors.validationFailures) {
					messageParts.push(validationMessageA,
						this.controlTargets.find(x => x.feature === failure.feature).description,
						validationMessageB,
						failure.target.toString(),
						'.\n');
				}
				messageParts.push(validationMessageFinal);

				return messageParts.join('');
			})
		);
	}

	onSaveOrApproveClick(vl: string, action: string) {

		this.specificationCommitmentList = [];
		this.wholesaleData.forEach(w => {
			this.specificationCommitmentList.push({
				wersCodes: w.id,
				commitmentCount: Number(w.dealerOrder)
			});
		});
		this.parentComponent.messageService.clear();
		this.wholesaleService.postWholesaleCommitments(action, this.dealerCode, this.specificationCommitmentList,
			this.productionCycle, this.market, this.vehicles.get(vl), this.volumeLevelCommitmentCount)
			.subscribe({
				next: () => {
					this.parentComponent.makeConfigCallAfterSaveApprove(vl);
				}, error: (response: HttpErrorResponse) => {
					if (response.status === 400 && isControlValidationFailure(response.error)) {
						this.buildControlValidationFailureToast(response.error)
							.subscribe(message => this.parentComponent.showMessageServiceError(message, true));
					} else {
						let translationKey = 'wbdo.wholesale.GeneralError';
						if (response.status === 400) {
							translationKey = 'wbdo.wholesale.400BadRequest';
						}
						if (response.status === 409) {
							translationKey = 'wbdo.wholesale.409Conflict';
							this.parentComponent.onVehicleLineChange(vl);
						}
						// eslint-disable-next-line max-len
						this.translate.get(translationKey).subscribe(message => this.parentComponent.showMessageServiceError(message, true));
					}
				}
			});
	}
}
